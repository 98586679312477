/* eslint-disable vue/no-unused-components */
<template>
	<div>
		<div id="digital" class="main-box park-program">
			<el-row>
				<el-col :span="24">
					<div class="main-description">大数据可视化平台</div>
					<div class="park-program-minSpan">visualization</div>
				</el-col>
			</el-row>
			<div class="video_part">
				<div
					class="video_item"
					v-for="(item, index) in swiperList"
					:key="index"
				>
					<video-player
						class="video-player vjs-custom-skin"
						ref="videoPlayer"
						:playsinline="true"
						style="object-fit:fill"
						:options="item.playerOptions"
						:x5-video-player-fullscreen="true"
						@pause="onPlayerPause($event)"
						@play="onPlayerPlay($event)"
						@fullscreenchange="onFullscreenChange($event)"
						@click="fullScreen"
					>
					</video-player>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { videoPlayer } from 'vue-video-player'
import 'video.js/dist/video-js.css'
import 'vue-video-player/src/custom-theme.css'
import bus from '@/utils/bus'

export default {
	components: { videoPlayer },
	props: {
		title: {
			//模块标题
			type: String,
			default: '',
		},
	},
	name: 'digital',
	data() {
		return {
			// 轮播数据
			swiperList: [
				{
					playerOptions: {
						playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
						autoplay: false, // 如果为true,浏览器准备好时开始回放。
						muted: false, // 默认情况下将会消除任何音频。
						loop: false, // 是否视频一结束就重新开始。
						preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
						language: 'zh-CN',
						aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
						fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
						sources: [
							{
								type: 'video/mp4', // 类型
								src: require('@/assets/发电设备全生命周期管理4K.mp4'), // url地址
							},
						],
						poster: require('@/assets/发电设备全生命周期管理.png'), // 封面地址
						notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
						controlBar: {
							timeDivider: true, // 当前时间和持续时间的分隔符
							durationDisplay: true, // 显示持续时间
							remainingTimeDisplay: false, // 是否显示剩余时间功能
							fullscreenToggle: true, // 是否显示全屏按钮
						},
					},
				},
				{
					playerOptions: {
						playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
						autoplay: false, // 如果为true,浏览器准备好时开始回放。
						loop: false, // 是否视频一结束就重新开始。
						fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
						aspectRatio: '16:9',
						sources: [
							{
								type: 'video/mp4', // 类型
								src: require('@/assets/新能源汽车产线数字孪生平台4K.mp4'), // url地址
							},
						],
						poster: require('@/assets/新能源汽车产线数字孪生平台.png'), // 封面地址
						notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
					},
				},
				{
					playerOptions: {
						playbackRates: [0.5, 1.0, 1.5, 2.0], // 可选的播放速度
						autoplay: false, // 如果为true,浏览器准备好时开始回放。
						loop: false, // 是否视频一结束就重新开始。
						fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
						aspectRatio: '16:9',
						sources: [
							{
								type: 'video/mp4', // 类型
								src: require('@/assets/全球疫情态势_蓝V3.mp4'), // url地址
							},
						],
						poster: require('@/assets/全球疫情态势.png'), // 封面地址
						notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
					},
				},
			],
			playTime: '',
			current: '',
		}
	},
	mounted() {
		bus.$on('digital-message', () => {
			this.$el.querySelector('#digital').scrollIntoView({ behavior: 'smooth' })
		})
	},
	methods: {
		fullScreen() {
			const player = this.$refs.videoPlayer.player
			player.requestFullscreen() //调用全屏api方法
			player.isFullscreen(true)
			player.play()
		},
		onPlayerPlay(player) {
			player.play()
		},
		onPlayerPause() {
			// alert("pause");
		},
	},

	computed: {
		player() {
			return this.$refs.videoPlayer.player
		},
	},
}
</script>
<style lang="less">
.video_part {
	display: flex;
	justify-content: space-around;
	.video_item {
		width: 30%;
	}
}
.vjs-icon-play:before,
.video-js .vjs-big-play-button .vjs-icon-placeholder:before,
.video-js .vjs-play-control .vjs-icon-placeholder:before {
	content: '';
}
.video-js .vjs-big-play-button {
	background: url('~@/assets/播放键.png') no-repeat;
	border: transparent !important;
	width: 2em !important;
}
.vjs-custom-skin > .video-js .vjs-big-play-button {
	background-color: transparent;
	font-size: 4em;
	/* border-radius: 50%; */
	// height: 1.5em !important;
	line-height: 1.5em !important;
	margin-top: -1em !important;
}
/*这里用了第三方vue-video-player插件，但这个插件有bug，设置globalSetting:{controls:true}隐藏进度条不生效，故可设置插件样式进行隐藏vjs-progress-contro*/
.vjs-progress-control {
	//  visibility: hidden; // 隐藏进度条
}
</style>
<style scoped lang="scss">
.swiper-container {
	height: 500px !important;
}
/*内容盒子*/
.main-box {
	padding: 70px 0;
}
// 产品与解决方案
.main-description {
	font-size: 40px;
	font-weight: bold;
	text-align: center;
}
.main-description:before {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 12px;
	width: 12px;
	margin-right: 40px;
	background-color: #2965ed;
}
.main-description:after {
	content: '';
	display: inline-block;
	vertical-align: middle;
	height: 12px;
	width: 12px;
	margin-left: 40px;
	background-color: #2965ed;
}
/*大数据可视化平台*/
.park-program {
	.park-program-minSpan {
		font-family: SourceHanSansCN-Normal;
		font-size: 20px;
		color: #e7e7e7;
		letter-spacing: 3.1px;
		text-align: center;
		margin: 30px 0;
	}
}
.swiper-container {
	width: 100%;
	height: 100%;
}
.swiper-slide {
	text-align: center;
	font-size: 18px;
	background: #fff;

	/* Center slide text vertically */
	display: -webkit-box;
	display: -ms-flexbox;
	display: -webkit-flex;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	-webkit-justify-content: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	-webkit-align-items: center;
	align-items: center;
	transition: 300ms;
	transform: scale(0.8);
	img {
		width: 100%;
		height: 100%;
	}
}
.swiper-slide-active,
.swiper-slide-duplicate-active {
	transform: scale(1);
}
</style>
